/* Customize your main colors in :root variables */
:root {
  --main-background-color: #343A50;
  --card-background-color: #804980;
  --countdown-background-color: #433765;
  --main-text-color:#F7F6F4;
  --title-text-color: #e07913;
}

body{
/*  background-color:var(--main-background-color);*/
  background: url("img/background.png");
  background-size: cover;
  font-family: 'Patrick Hand', cursive;
  font-size: 1.1em;
}
	

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}



.brandname {
  font-size: 2rem;
  letter-spacing: 1px;
}

.navbar {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 0;
  position: relative;
}

.navlinks {
  display: flex;
  gap: 2rem;
}

.navlinks li,
.navlinks p {
  font-size: 0.85rem;
  list-style: none;
  cursor: pointer;
}

.navlinks li:hover,
.navlinks p:hover {
  filter: brightness(0.7);
}

.borderdownshadow {
  color: gray;
  position: absolute;
  width: 35%;
  bottom: 1%;
  left: 50%;
  transform: translate(-50%, 0);
  overflow: visible;
  border-bottom: 2px solid;
  border-radius: 2px;
}

.toparea {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
}

.toparea .areatxt {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.toparea .areatxt .bigtxt {
  margin: 0;
  font-size: 14rem;
  letter-spacing: 5px;
  word-spacing: 40px;
}

.toparea .areatxt .smalltxt {
  margin-top: -1.5rem;
  text-transform: uppercase;
}

.toparea .dicovarea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 4rem;
}

.toparea .dicovarea .smtxt {
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 3rem;
}

.toparea .icons {
  position: absolute;
  bottom: 6rem;
  right: 3rem;
  transform: scale(0.95);
}

.toparea .icons ul {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.toparea .icons ul li {
  list-style: none;
}

.toparea .icons ul li .path-cnt {
  margin-left: -0.5rem;
  margin-top: -0.2rem;
}

.toparea .icons ul li:hover {
  filter: brightness(0.8);
}

.din {
  font-family: "DIN";
}

.areatxt {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.areatxt .bigtxt {
  margin: 0;
  font-size: 800% !important;
  letter-spacing: 1em;
  word-spacing: 40px;
  text-decoration: solid;
}

.areatxt .smalltxt {
  margin-top: -1.5rem;
  text-transform: uppercase;
}

.mint {
  background: rgba(255, 255, 255, 0);
  color: white;
  transition: 0.3s;
}

.mint .mintarea {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
  padding-top: 3rem;
}

.mint .mintarea .topminttxt {
  position: relative;
}

.mint .mintarea .topminttxt .bigbgtxt {
  font-size: 8rem;
  letter-spacing: 1.5rem;
  opacity: 0.3;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.mint .mintarea .topminttxt .smalltxt {
  font-size: 2rem;
  letter-spacing: 0.5rem;
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.mint .mintarea .mintcount {
  font-size: 1.1rem;
}

.mint .mintarea .countbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
}

.mint .mintarea .countbar .circ {
  cursor: default;
}

.mint .mintarea .countbar .bar {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 0.7rem;
  color: black;
  text-align: center;
  width: 8rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.mint .mintarea .mintbtn {
  padding-left: 3.3rem;
  padding-right: 3.3rem;
}

.mint .mintarea .showmintedpage {
  margin-top: 1rem;
  font-size: 0.8rem;
  letter-spacing: 2px;
  max-width: 80%;
  background-color: #363636 aa;
  padding: 0.8rem 1.5rem;
  border-radius: 0.4rem;
  cursor: default;
}

.mint .mintarea .mintsvgs {
  position: absolute;
}

.mint .mintarea .mintsvgs .circ1 {
  position: absolute;
  left: 45vw;
  bottom: 5px;
}

.mint .mintarea .mintsvgs .squ1 {
  transform: scale(0.4);
  opacity: 0.3;
  position: absolute;
  left: 27vw;
  top: -8rem;
  margin: 0;
  padding: 0;
}

.mint .mintarea .mintsvgs .squ2 {
  transform: scale(0.4);
  opacity: 0.3;
  position: absolute;
  right: 38vw;
  top: -10rem;
  margin: 0;
  padding: 0;
}

.areatxt .carousel {
  z-index: -200;
  background: url("img/slider.png") center center / cover;
  height: 300px;
  animation: carousel 2000s linear infinite;
  transition: 0.3s;
  display: inherit;
}

@keyframes carousel {
  from {
    background-position: 0;
  }

  to {
    background-position: -100000px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  cursor: default;
}

* {
  box-sizing: border-box;
}

.inactive {
  filter: brightness(0.8);
}

.pointer {
  cursor: pointer;
}

.oranbtn {
  cursor: pointer;
  font-size: 0.85rem;
  background-color: #e07913;
  border-radius: 3rem;
  padding: 1.8rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  color: white;
  font-family: "Poppins";
  font-weight: 100;
}

.oranbtn:hover {
  filter: brightness(0.78);
}

@font-face {
  font-family: "DIN";
  src: url("fonts/Dincondensed.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "DIN";
  src: url("fonts/DIN-Regular.ttf");
  font-weight: 200;
}

.din2 {
  font-family: "DIN";
  font-weight: 200;
}

.din {
  font-family: "DIN";
  font-weight: 100;
}

@font-face {
  font-family: "ProductSans";
  src: url("fonts/ProductSansRegular.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "ProductSans";
  src: url("fonts/ProductSans-Bold.ttf");
  font-weight: 200;
}

.ps1 {
  font-family: "ProductSans";
  font-weight: 100;
}

.ps2 {
  font-family: "ProductSans";
  font-weight: 200;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-Regular.otf");
  font-weight: 100;
}

.pop {
  font-family: "Poppins";
  font-weight: 100;
}
